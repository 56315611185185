import { GatsbyImage } from "gatsby-plugin-image"
import gsap from "gsap"
import React, { useEffect } from "react"
import { useButtonText } from "../hooks/useButtonText"
import { useLanguage } from "../hooks/useLanguage"
import Button from "./button"
import Link from "./link"

const Introduction = ({ title, overline, captions }) => {
    const { switchLanguage } = useLanguage()
    const btnText = useButtonText()
    useEffect(() => {
        var tl = gsap.timeline({ paused: true, repeat: -1, delay: 1 })
        tl.to('.icon[data-icon="1"]', { borderColor: "#66CF2D", duration: 0.75, ease: "ease", delay: 1 })
        tl.to('.icon[data-icon="2"]', { borderColor: "#66CF2D", duration: 0.75, ease: "ease" })
        tl.to('.icon[data-icon="3"]', { borderColor: "#66CF2D", duration: 0.75, ease: "ease" })
        tl.to(".icon", { borderColor: "rgba(131, 143, 141, 0.25)", duration: 0.75, delay: 1, ease: "ease" })
        tl.play()
    }, [])
    return (
        <div className='introduction p60 max-1600 ma flex flex-wrap m-p20 m-flex-c m-pt40'>
            <div className='w-50 flex flex-wrap m-w-100 '>
                <div className='w-100 pr60'>
                    <p className='m0 h3 text-grey mb20 m-small m-mt40 m-mb5 '>{overline}</p>
                    <p className='m0 h2 max-600'>{title}</p>
                    <div>
                        <Button as={Link} to='/contact' text={switchLanguage(btnText.titleJp, btnText.title)} className='outline mt60 m-mt40 m-mb20 inline-block' />
                    </div>
                </div>
            </div>
            <div className='w-50 m-w-100 '>
                {captions.map((brands, index) => {
                    return (
                        <div className='mta pl20 m-p0 m-mt40 flex mb40 align-center' key={index}>
                            <div className='mr40'>
                                <div className='icon p20 flex align-center pos-rel' data-icon={index + 1}>
                                    <GatsbyImage className='bg-image' alt='Intro Icon' image={brands.icon?.gatsbyImageData} />
                                </div>
                            </div>
                            <div className='content'>
                                <p className='m0 mb20'>{switchLanguage(brands.titleJap, brands.title)}</p>
                                <p className='m0 text-grey max-450'>{switchLanguage(brands.descriptionJap, brands.description)}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Introduction
