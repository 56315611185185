import React from 'react'
import { H2 } from './fonts'
import AssociatedBrands from './associatedBrands'
import { graphql } from 'gatsby'

export default function TrustedBy({ title, brands }) {
    return (
        <div className="brands p60 text-center max-1000 ma m-p20 m-pb60 m-pt40">
            <H2 className='mb60'>{title}</H2>
            <AssociatedBrands asscoiatedBrands={brands} />
        </div>
    )
}


export const query = graphql`
    fragment TrustedBrand on DatoCmsTrustedBy{
        name
        icon{
            url
        }
    }
`