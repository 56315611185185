import { graphql } from "gatsby"
import * as React from "react"
import HeroBanner from "../components/heroBanner"
import ImageCard from "../components/imageCard"
import Introduction from "../components/introduction"
import LatestNews from "../components/latestNews"
import Layout from "../components/layout"
import PageNavigation from "../components/pageNavigation"
import PartneredWith from "../components/partneredWith"
import Products from "../components/products"
import Seo from "../components/seo"
import Spacer from "../components/spacer"
import TrustedBy from "../components/trustedBy"

import ChangeHeaderInView from "../components/changeHeaderinView"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import { getCtaDataHome, getJapaneseCtaDataHome, getProductDataThumbnail, resolveToPartneredBrands, resolveToTrustedBrands } from "../utils/dataFetchers"

export default function IndexPage({ data }) {
    const homePage = data.allDatoCmsHomepage.edges[0].node
    const { isJapanese, switchLanguage } = useLanguage()
    return (
        <Layout>
            <Seo title='Home' />
            <HeroBanner mobileImage={homePage.mobileHeroImage} mobileVideo={homePage.mobileHeroVideoUrl} title={switchLanguage(homePage.heroTitleJap, homePage.heroTitle)} video={homePage.heroVideoUrl} image={homePage.heroImage} />
            <ChangeHeaderInView threshold={[0.1]} color={COLOR_STATES.DARK}>
                <PageNavigation />
                <Introduction title={switchLanguage(homePage.introTitleJap, homePage.introTitle)} overline={switchLanguage(homePage.introOverlineJap, homePage.introOverline)} captions={homePage.captions} />
                <Spacer className='m-hide' />
                <Products products={getProductDataThumbnail(data)} title={switchLanguage(homePage.ourProductTitleJp, homePage.ourProductTitle)} />
                <PartneredWith brands={resolveToPartneredBrands(data.partners)} title={switchLanguage(homePage.partnerTitleJp, homePage.partnerTitle)} />
                <ImageCard slug='/why' cta={isJapanese ? getJapaneseCtaDataHome(homePage) : getCtaDataHome(homePage)} />
                <LatestNews news={data.pageData.news} title={switchLanguage(homePage.newsTitleJp, homePage.newsTitle)} />
                <TrustedBy brands={resolveToTrustedBrands(data.trusted)} title={switchLanguage(homePage.trustTitleJp, homePage.trustTitle)} />
                <Spacer className='m-hide' />
            </ChangeHeaderInView>
        </Layout>
    )
}

export const query = graphql`
    query HomepageQuery {
        pageData: datoCmsHomepage {
            subtitle
            subtitleJap
            mobileTitle
            mobileTitleJap
            news {
                id
                ...NewsCard
            }
        }
        allDatoCmsHomepage {
            edges {
                node {
                    mobileHeroVideoUrl
                    mobileHeroImage {
                        gatsbyImageData
                    }
                    videoUrl
                    heroTitle
                    headingJap
                    heroTitleJap
                    introOverlineJap
                    introTitleJap
                    mobileTitleJap
                    subHeadingJap
                    subtitleJap
                    heroImage {
                        gatsbyImageData
                    }
                    heroVideoUrl
                    introTitle
                    introOverline
                    ourProductTitle
                    partnerTitle
                    newsTitle
                    trustTitle
                    ourProductTitleJp
                    partnerTitleJp
                    newsTitleJp
                    trustTitleJp
                    captions {
                        title
                        titleJap
                        description
                        descriptionJap
                        icon {
                            gatsbyImageData
                        }
                    }
                    subHeading
                    heading
                    videoUrl
                    image {
                        gatsbyImageData
                    }
                    icon {
                        url
                    }
                }
            }
        }
        blogs: allDatoCmsNews(limit: 3) {
            nodes {
                ...NewsCard
            }
        }
        allDatoCmsOffering {
            nodes {
                ...ProductThumbnail
            }
        }
        partners: allDatoCmsPartneredWith(sort: { fields: position }) {
            nodes {
                ...PartneredBrand
            }
        }
        trusted: allDatoCmsTrustedBy(sort: { fields: position }) {
            nodes {
                ...TrustedBrand
            }
        }
    }
`
