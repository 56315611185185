import { graphql } from 'gatsby'
import React from 'react'
import AssociatedBrands from './associatedBrands'
import { H2 } from './fonts'

export default function PartneredWith({ title, brands }) {
  return (
    <div className="brands p60 text-center max-1000 ma m-p20   w-75 m-w-100 border-box m-mb20">
      <H2 className='mb60 m-mb40'>{title}</H2>
      <AssociatedBrands asscoiatedBrands={brands} />
    </div>
  )
}


export const query = graphql`
  fragment PartneredBrand on DatoCmsPartneredWith{
    name
    icon{
        url
    }
  }
`