import * as React from "react"
import { useButtonText } from "../hooks/useButtonText"
import { useLanguage } from "../hooks/useLanguage"
import CtaButton from "./ctaButton"
import { H2 } from "./fonts"
import IconNText from "./iconNText"
import Link from "./link"
import OverlayConatiner from "./overlayConatiner"
import VideoOrImage from "./videoOrImage"

const ImageCard = ({ cta, slug, imgClassName }) => {
    const { switchLanguage } = useLanguage()
    const btnText = useButtonText()
    return (
        <div className='image--card p60 max-1600 ma m-p20 m-pb40 '>
            <OverlayConatiner as={Link} to={slug} className='pt50p bg-grey block hover-image--zoom m-pt120'>
                <div className='z-2 text--overlay p60 text-white m-p20'>
                    <IconNText icon={cta.icon}>{cta.heading}</IconNText>
                    <H2 className='mb30'>{cta.subHeading}</H2>
                    <div className='flex'>
                        <CtaButton as={"div"} children={switchLanguage(btnText.readTitleJp, btnText.readTitle)} />
                    </div>
                </div>
                <VideoOrImage videoWhen={cta.video} video={cta.video} image={cta.image} className={"bg-image filter-brightness z-1 cover " + imgClassName} />
            </OverlayConatiner>
        </div>
    )
}

export default ImageCard
