import React from 'react'
import { Tiny } from './fonts'
import { ReactComponent as Arrow } from '../images/arrow1.svg'
import Link from './link'

export default function ReadMore({ children = 'Read More', hover, as = Tiny, linkAs = Link, className = '', textClassName = '', hideArrowMobile = true, ...props }) {
    const As = as
    const LinkAs = linkAs
    return (
        <LinkAs className={'read-more flex align-center   ' + className} {...props}>
            <Arrow className={'mr10 ' + (hideArrowMobile ? 'm-hide' : '')} />
            <div className='inner-content' >
                <As className={textClassName} >{children}</As>
            </div>
        </LinkAs>
    )
}
