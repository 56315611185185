import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { resolveToMedia } from "../utils/dataFetchers"
import CtaButton from "./ctaButton"
import { H2 } from "./fonts"
import IconNText from "./iconNText"
import Link from "./link"
import VideoOrImage from "./videoOrImage"

import "swiper/css"
import "swiper/css/navigation"
import { useButtonText } from "../hooks/useButtonText"
import { useLanguage } from "../hooks/useLanguage"
import Spacer from "./spacer"

const Products = ({ title, products }) => {
    return (
        <div className='products m-pt60'>
            <p className='m0 h3 p60 m-p20 max-1600 ma flex justify-between' id='product'>
                <span>{title}</span>
                <menu className='mla'>
                    <button id='prevEl'>
                        <span className='prev' />
                    </button>
                    <button id='nextEl'>
                        <span className='next' />
                    </button>
                </menu>
            </p>
            <Carousel products={products} />
            <Spacer />
        </div>
    )
}

const Carousel = ({ products }) => {
    const [distanceToMoveCarousel, setDistanceToMoveCarousel] = useState(0)

    useEffect(() => {
        function calculateDistanceToMove() {
            const container = document.querySelector("#product")
            setDistanceToMoveCarousel(() => Math.round(container.getBoundingClientRect().left) + parseInt(window.getComputedStyle(container, null).getPropertyValue("padding-left")))
        }
        calculateDistanceToMove()

        window.addEventListener("resize", calculateDistanceToMove)
        return () => window.removeEventListener("resize", calculateDistanceToMove)
    }, [])

    return (
        <Swiper
            spaceBetween={30}
            breakpoints={{
                0: { slidesPerView: 1 },
                500: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1450: { slidesPerView: 3 },
            }}
            slidesOffsetBefore={distanceToMoveCarousel}
            slidesOffsetAfter={distanceToMoveCarousel}
            modules={[Navigation]}
            navigation={{ nextEl: "#nextEl", prevEl: "#prevEl" }}>
            {products.map((product, index) => {
                return (
                    <SwiperSlide key={index}>
                        <Product product={product} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

const Product = ({ product }) => {
    const { switchLanguage } = useLanguage()
    const btnText = useButtonText()
    const media = resolveToMedia(product)
    return (
        <Link to={`/products/${product.slug}`} className='pos-rel z-3 overflow-hidden br-10 ratio-1-2 t-pt100 m-pt150 bg-grey hover-image--zoom'>
            <div className='bg-image z-2 p60 t-p20 text-white border-box m-p20'>
                <div className='flex flex-col justify-between h-100 '>
                    <div>
                        <IconNText className='f600 flex align-center ' icon={product.icon}>
                            {switchLanguage(product.nameJp, product.name)}
                        </IconNText>
                        <H2 className='max-500 m-h2  '> {switchLanguage(product.headingJp, product.heading)}</H2>
                    </div>
                    <div className='flex'>
                        <CtaButton as={"div"} children={switchLanguage(btnText.readTitleJp, btnText.readTitle)} />
                    </div>
                </div>
            </div>
            <VideoOrImage mobileImage={media.mobileImage} mobileVideo={media.mobileVideo} videoWhen={media.video} video={media.video} image={media.image} className={"bg-image filter-brightness z-1 cover "} />
        </Link>
    )
}

export default Products

export const query = graphql`
    fragment ProductThumbnail on DatoCmsOffering {
        name
        nameJp
        slug
        featuredVideoUrl
        featuredImage {
            gatsbyImageData
        }
        heading
        headingJp
        icon {
            url
        }
    }
`
